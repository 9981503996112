import Vue from "vue";
import VueRouter from "vue-router";
import { setToken, setToken1 } from "@/unit/auth";
import { getSettings } from "@/api/screen";
import store from "@/store";
Vue.use(VueRouter);

const routes = [
  {
    path: "/pageTwo",
    name: "pageTwo",
    meta: {
      tpl_id: 2,
    },
    component: () => import("../views/pageTwo/pageTwo.vue"),
  },

  {
    path: "/guangfu1",
    name: "光伏1",
    meta: {
      tpl_id: 4,
    },
    component: () => import("../views/guangfu1/index"),
  },
  {
    path: "/guangfu2",
    name: "光伏2",
    meta: {
      tpl_id: 4,
    },
    component: () => import("../views/guangfu2/index"),
  },
  {
    path: "/",
    component: () => import("../components/Head.vue"),
    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "index",
        meta: {
          tpl_id: 1,
        },
        component: () => import("../views/index/index"),
      },

      {
        path: "/page3",
        name: "大屏3",
        meta: {
          tpl_id: 3,
        },
        component: () => import("../views/screen3.vue"),
      },
      {
        path: "/guangfu",
        name: "光伏",
        meta: {
          tpl_id: 4,
        },
        component: () => import("../views/guangfu/index"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "hash",
  routes,
});
router.beforeEach((form, to, next) => {
  console.log("form", form);
  console.log(to);
  if (form.query.token != undefined) {
    setToken(form.query.token);
  }
  if (form.query.token1 != undefined) {
    setToken1(form.query.token1);
  }
  getSettings({ tpl_id: form.meta.tpl_id }).then((res) => {
    // eslint-disable-next-line no-undef

    store.commit("screen/CHANGE_SETTING", res.res);
    next();
  });
  console.log(next);
});
export default router;
