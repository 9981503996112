import request from "@/unit/request";
//站点告警
export function getBigDataIndex(params) {
  return request({
    url: "/api/v2/getBigDataIndex",
    method: "get",
    params,
  });
}
//站点cpu
export function getBigDataServer(params) {
  return request({
    url: "/api/getBigDataServer",
    method: "get",
    params,
  });
}
//测点统计
export function getBigDataMeasures(params) {
  return request({
    url: "/api/v2/getBigDataMeasures",
    method: "get",
    params,
  });
}
export function getBigDataMsgs(params) {
  return request({
    url: "/api/v2/getBigDataMsgs",
    method: "get",
    params,
  });
}

//获取今日负荷趋势
export function getBigDataLoad(params) {
  return request({
    url: "/api/v2/getBigDataLoad",
    method: "get",
    params,
  });
}
//获取报警信息
export function getBigDataAlarms(params) {
  return request({
    url: "/api/v2/getBigDataAlarms",
    method: "get",
    params,
  });
}
export function getSafeDays(params) {
  return request({
    url: "/api/v2/getSafeDays",
    method: "get",
    params,
  });
}
//报警图
export function getBigDataAlarmnNum(params) {
  return request({
    url: "/api/v2/getBigDataAlarmnNum",
    method: "get",
    params,
  });
}
export function getBigDataTasks(params) {
  return request({
    url: "/api/v2/getBigDataTasks",
    method: "get",
    params,
  });
}
export function getBigDataMap(params) {
  return request({
    url: "/api/v2/getBigDataMap",
    method: "get",
    params,
  });
}
export function getWeather(params) {
  return request({
    url: "/api/v2/getWeather",
    method: "get",
    params,
  });
}

/**
 * 获取设置
 * @param params
 * @returns {AxiosPromise}
 */
export function getSettings(params) {
  return request({
    url: "/api/v2/screen/getSettings",
    method: "get",
    params,
  });
}
export function getAlarmNum(params) {
  return request({
    url: "/api/getAlarmNum",
    method: "get",
    params,
  });
}
export function getAlarms(params) {
  return request({
    url: "/api/v2/getAlarms",
    method: "get",
    params,
  });
}
export function getAlarms1(params) {
  return request({
    url: "/api/v2/getAlarms1",
    method: "get",
    params,
  });
}
