import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import animated from "animate.css";
Vue.use(animated);
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import BaiduMap from "@/components/vue-baidu-map/components"; // map
// //element-ui样式引入
// import "element-ui/lib/theme-chalk/index.css";
// //element-ui文件夹下
// import element from "./element-ui/index";
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  ak: "ziq74kGuGFe7HASYuRm6ipXg",
});
import "./assets/css/base.css";
import "./assets/css/uviewrem.css";
import "./assets/css/main.scss";
import "./assets/css/zhengfu.css";
import "./assets/css/element.scss";
import "./assets/js/rem";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
