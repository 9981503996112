const TokenKey = "vue_admin_template_token";
const Token1 = "token1";
export function getToken() {
  let token = localStorage.getItem(TokenKey);
  return token;
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token);
}

export function getToken1() {
  let token = localStorage.getItem(Token1);
  if (!token) {
    token = "45|JZmcelk9GzHh0z9zO2WXDYJx34JoKjmGpvphybH4";
  }
  return token;
}

export function setToken1(token) {
  return localStorage.setItem(Token1, token);
}
