const state = {
  title: "",
  server_id: 1,
  time: 300000,
};

const mutations = {
  CHANGE_SETTING: (state, settting) => {
    state.title = settting.title ? settting.title : "智能运维云平台数据大屏";
    state.server_id = settting.server_id ? settting.server_id : 1;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
